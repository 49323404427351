import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { baseUrl } from '../../../AppConst';

const initialState = {
  subscriptions: [],
  selectedSubscription: null,
  loading: false,
  error: null,
};

export const subscriptionSlice = createSlice({
  name: 'reportReducer',
  initialState,
  reducers: {
    getAttendanceReport: (state, action) => {
      state.attendance = action.payload;
      state.loading = false;
      state.error = null;
    },
    getMonthlyExpesneReport: (state, action) => {
      state.attendance = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { getAttendanceReport, getMonthlyExpesneReport, setLoading, setError } =
  subscriptionSlice.actions;

// Function to add or update a Subscription
export const fetchCollectionReport = (attendaceReportData) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(
      `${baseUrl}/reports/CollectionReport`,
      attendaceReportData,
      config,
    );
    if (response.status === 200 || response.data.success === true) {
      dispatch(getAttendanceReport(response.data.data[0]));
      return Promise.resolve(response.data.data[0]);
    }
    dispatch(setError('Failed to load Attendace Report.'));
    return Promise.reject(new Error('Failed to load Attendace Report.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};

export const fetchExpensesReport = (attendaceReportData) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(
      `${baseUrl}/reports/ExpensesReport`,
      attendaceReportData,
      config,
    );
    if (response.status === 200 || response.data.success === true) {
      dispatch(getAttendanceReport(response.data.data[0]));
      return Promise.resolve(response.data.data[0]);
    }
    dispatch(setError('Failed to load Attendace Report.'));
    return Promise.reject(new Error('Failed to load Attendace Report.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};

export const fetchMonthlyExpensesReport = (attendaceReportData) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(
      `${baseUrl}/reports/MonthlyExpensesReport`,
      attendaceReportData,
      config,
    );
    if (response.status === 200 || response.data.success === true) {
      dispatch(getMonthlyExpesneReport(response.data.data[0]));
      return Promise.resolve(response.data.data[0]);
    }
    dispatch(setError('Failed to load Attendace Report.'));
    return Promise.reject(new Error('Failed to load Attendace Report.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};

export const fetchLeaveReport = (attendaceReportData) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(
      `${baseUrl}/reports/LeaveReport`,
      attendaceReportData,
      config,
    );
    if (response.status === 200 || response.data.success === true) {
      dispatch(getAttendanceReport(response.data.data[0]));
      return Promise.resolve(response.data.data[0]);
    }
    dispatch(setError('Failed to load Attendace Report.'));
    return Promise.reject(new Error('Failed to load Attendace Report.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};
export const fetchOrdersReport = (attendaceReportData) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(
      `${baseUrl}/reports/OrderItemReport`,
      attendaceReportData,
      config,
    );
    if (response.status === 200 || response.data.success === true) {
      dispatch(getAttendanceReport(response.data.data[0]));
      return Promise.resolve(response.data.data[0]);
    }
    dispatch(setError('Failed to load Attendace Report.'));
    return Promise.reject(new Error('Failed to load Attendace Report.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};
export const fetchTaskReport = (attendaceReportData) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(`${baseUrl}/reports/TaskReport`, attendaceReportData, config);
    if (response.status === 200 || response.data.success === true) {
      dispatch(getAttendanceReport(response.data.data[0]));
      return Promise.resolve(response.data.data[0]);
    }
    dispatch(setError('Failed to load Attendace Report.'));
    return Promise.reject(new Error('Failed to load Attendace Report.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};
export const fetchTripMeterReport = (attendaceReportData) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(
      `${baseUrl}/reports/TripMeterReport`,
      attendaceReportData,
      config,
    );
    if (response.status === 200 || response.data.success === true) {
      dispatch(getAttendanceReport(response.data.data[0]));
      return Promise.resolve(response.data.data[0]);
    }
    dispatch(setError('Failed to load Attendace Report.'));
    return Promise.reject(new Error('Failed to load Attendace Report.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};
export const fetchAttendaceReport = (attendaceReportData) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(
      `${baseUrl}/reports/AttendaceReport`,
      attendaceReportData,
      config,
    );
    if (response.status === 200 || response.data.success === true) {
      dispatch(getAttendanceReport(response.data.data[0]));
      return Promise.resolve(response.data.data[0]);
    }
    dispatch(setError('Failed to load Attendace Report.'));
    return Promise.reject(new Error('Failed to load Attendace Report.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};

export const fetchDemonstrationReport = (attendaceReportData) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(
      `${baseUrl}/reports/DemonstrationReport`,
      attendaceReportData,
      config,
    );
    if (response.status === 200 || response.data.success === true) {
      dispatch(getAttendanceReport(response.data.data[0]));
      return Promise.resolve(response.data.data[0]);
    }
    dispatch(setError('Failed to load Demonstration Report.'));
    return Promise.reject(new Error('Failed to load Demonstration Report.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};

export const fetchFarmerMeetingReport = (attendaceReportData) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(
      `${baseUrl}/reports/FarmerMeetingReport`,
      attendaceReportData,
      config,
    );
    if (response.status === 200 || response.data.success === true) {
      dispatch(getAttendanceReport(response.data.data[0]));
      return Promise.resolve(response.data.data[0]);
    }
    dispatch(setError('Failed to load Demonstration Report.'));
    return Promise.reject(new Error('Failed to load Demonstration Report.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};

export const fetchGeoTrackingReport = (attendaceReportData) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const accessToken = window.localStorage.getItem('accessToken'); // Get access token from local storage
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await axios.post(
      `${baseUrl}/reports/GeoTrackingReport`,
      attendaceReportData,
      config,
    );
    if (response.status === 200 || response.data.success === true) {
      dispatch(getAttendanceReport(response.data.data[0]));
      return Promise.resolve(response.data.data[0]);
    }
    dispatch(setError('Failed to load GeoTracking Report.'));
    return Promise.reject(new Error('Failed to load GeoTracking Report.'));
  } catch (error) {
    dispatch(setError(error.message));
    return Promise.reject(error);
  }
};
///DemonstrationReport
export default subscriptionSlice.reducer;

import { lazy, React } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
import ProtectedRoute from './ProtectRoute'; // Import the ProtectedRoute component

/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Pro')));
const Minimal = Loadable(lazy(() => import('../views/dashboards/Minimal')));
const Analytical = Loadable(lazy(() => import('../views/dashboards/Analytical')));
const Demographical = Loadable(lazy(() => import('../views/dashboards/Demographical')));
const Modern = Loadable(lazy(() => import('../views/dashboards/Modern')));

const AddCompany = Loadable(lazy(() => import('../views/companies/AddCompany')));
const Companies = Loadable(lazy(() => import('../views/companies/companies')));
const ViewCompany = Loadable(lazy(() => import('../views/companies/ViewCompany')));

const AddUser = Loadable(lazy(() => import('../views/user/AddUser')));
const User = Loadable(lazy(() => import('../views/user/User')));
const ViewUser = Loadable(lazy(() => import('../views/user/ViewUser')));
const AddProduct = Loadable(lazy(() => import('../views/product/AddProduct')));
const Product = Loadable(lazy(() => import('../views/product/Product')));
const ViewProduct = Loadable(lazy(() => import('../views/product/ViewProduct')));
const EditProduct = Loadable(lazy(() => import('../views/product/EditProduct')));

const AddSubscriptions = Loadable(lazy(() => import('../views/subscriptions/AddSubscriptions')));
const Subscriptions = Loadable(lazy(() => import('../views/subscriptions/Subscriptions')));
const ViewSubscriptions = Loadable(lazy(() => import('../views/subscriptions/ViewSubscriptions')));

const AddDesignations = Loadable(lazy(() => import('../views/Designation/AddDesignation')));
const Designations = Loadable(lazy(() => import('../views/Designation/Designation')));
const ViewDesignations = Loadable(lazy(() => import('../views/Designation/ViewDesignation')));

const AccessControl = Loadable(lazy(() => import('../views/accessControl/AccessControl')));
const AddFarmer = Loadable(lazy(() => import('../views/farmer/AddFarmer')));
const Farmers = Loadable(lazy(() => import('../views/farmer/Farmers')));
const ViewFarmer = Loadable(lazy(() => import('../views/farmer/ViewFarmer')));  
const AddVendor = Loadable(lazy(() => import('../views/vendor/AddVendor')));
const Vendors = Loadable(lazy(() => import('../views/vendor/Vendors')));
const ViewVendor = Loadable(lazy(() => import('../views/vendor/ViewVendor')));
const Reports = Loadable(lazy(() => import('../views/Reports/ReportMaster')));
const AttendaceReport = Loadable(
  lazy(() => import('../views/Reports/Attendance/AttendanceReport')),
);
const DetailAttendanceReport = Loadable(
  lazy(() => import('../views/Reports/Attendance/DetailAttendanceReport')),
);
const DeatilCollectionReport = Loadable(
  lazy(() => import('../views/Reports/Collection/DeatilCollectionReport')),
);
const DetailExpensesReport = Loadable(
  lazy(() => import('../views/Reports/Expenses/DetailExpensesReport')),
);
const DetailLeaveReport = Loadable(lazy(() => import('../views/Reports/Leave/DetailLeaveReport')));
const DetailOrdersReport = Loadable(
  lazy(() => import('../views/Reports/Orders/DetailOrdersReport')),
);
const DetailTaskReport = Loadable(lazy(() => import('../views/Reports/Task/DetailTaskReport')));
const DetailTripMeterReport = Loadable(
  lazy(() => import('../views/Reports/TripMeter/DetailTripMeterReport')),
);
const TripMeterReport = Loadable(lazy(() => import('../views/Reports/TripMeter/TripMeterReport')));
const TaskReport = Loadable(lazy(() => import('../views/Reports/Task/TaskReport')));
const ExpensesReport = Loadable(lazy(() => import('../views/Reports/Expenses/ExpensesReport')));
const MonthlyExpensesReport = Loadable(
  lazy(() => import('../views/Reports/MonthlyExpenses/MonthlyExpensesReport')),
);
const DetailMonthlyExpensesReport = Loadable(
  lazy(() => import('../views/Reports/MonthlyExpenses/DetailMonthlyExpensesReport')),
);
const CollectionsReport = Loadable(
  lazy(() => import('../views/Reports/Collection/CollectionReport')),
);
const OrdersReport = Loadable(lazy(() => import('../views/Reports/Orders/OrdersReport')));
const LeaveReport = Loadable(lazy(() => import('../views/Reports/Leave/LeaveReport')));
const DemonstrationReport = Loadable(
  lazy(() => import('../views/Reports/Demonstration/DemonstrationReport')),
);
const DetailDemonstrationReport = Loadable(
  lazy(() => import('../views/Reports/Demonstration/DetailDemonstrationReport')),
);
const FarmerMeetingReport = Loadable(
  lazy(() => import('../views/Reports/FarmerMeeting/FarmerMeetingReport')),
);
const DetailFarmerMeetingReport = Loadable(
  lazy(() => import('../views/Reports/FarmerMeeting/DetailFarmerMeetingReport')),
);
const GeoTrackingReport = Loadable(
  lazy(() => import('../views/Reports/GeoTracking/GeoTrackingReport')),
);
const DetailGeoTrackingReport = Loadable(
  lazy(() => import('../views/Reports/GeoTracking/DetailGeoTrackingReport')),
);

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const Login = Loadable(lazy(() => import('../views/auth/Login')));
const Maintanance = Loadable(lazy(() => import('../views/auth/Maintanance')));
const LockScreen = Loadable(lazy(() => import('../views/auth/LockScreen')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));
const PrivacyPolicy = Loadable(lazy(() => import('../views/auth/PrivcyPolicy')));
const DeleteAccount = Loadable(lazy(() => import('../views/auth/DeleteAccount')));

const ThemeRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', name: 'Home', element: <Navigate to="/auth/login" /> },
      { path: '/dashboard', name: 'Dashboard', exact: true, element: <Dashboard /> },
      { path: '/dashboards/minimal', name: 'Minimal', exact: true, element: <Minimal /> },
      { path: '/dashboards/analytical', name: 'Analytical', exact: true, element: <Analytical /> },
      {
        path: '/dashboards/demographical',
        name: 'Demographical',
        exact: true,
        element: <Demographical />,
      },
      { path: '/dashboards/modern', name: 'Modern', exact: true, element: <Modern /> },
      {
        path: '/company/New',
        name: 'AddCompany',
        exact: true,
        element: (
          <ProtectedRoute component={AddCompany} requiredAccess={2} moduleName="Add Company" />
        ),
      },
      {
        path: '/company/All',
        name: 'Companies',
        exact: true,
        element: <ProtectedRoute component={Companies} requiredAccess={3} moduleName="Companies" />,
      },
      {
        path: '/company/view/:CompanyId',
        name: 'ViewCompany',
        exact: true,
        element: (
          <ProtectedRoute component={ViewCompany} requiredAccess={3} moduleName="Companies" />
        ),
      },
      {
        path: '/user/New',
        name: 'AddUser',
        exact: true,
        element: <ProtectedRoute component={AddUser} requiredAccess={7} moduleName="Add User" />,
      },
      {
        path: '/user/All',
        name: 'User',
        exact: true,
        element: <ProtectedRoute component={User} requiredAccess={8} moduleName="Users" />,
      },
      {
        path: '/user/view/:userId',
        name: 'ViewUser',
        exact: true,
        element: <ProtectedRoute component={ViewUser} requiredAccess={7} moduleName="Users" />,
      },
      {
        path: '/subscription/New',
        name: 'AddSubscription',
        exact: true,
        element: (
          <ProtectedRoute
            component={AddSubscriptions}
            requiredAccess={4}
            moduleName="Add Subscription"
          />
        ),
      },
      {
        path: '/subscription/All',
        name: 'Subscriptions',
        exact: true,
        element: (
          <ProtectedRoute component={Subscriptions} requiredAccess={5} moduleName="Subscriptions" />
        ),
      },
      {
        path: '/subscription/view/:SubscriptionId',
        name: 'ViewCompany',
        exact: true,
        element: (
          <ProtectedRoute
            component={ViewSubscriptions}
            requiredAccess={5}
            moduleName="Subscriptions"
          />
        ),
      },
      {
        path: '/designation/New',
        name: 'Designation',
        exact: true,
        element: (
          <ProtectedRoute
            component={AddDesignations}
            requiredAccess={17}
            moduleName="Add Designation"
          />
        ),
      },
      {
        path: '/designation/All',
        name: 'Designations',
        exact: true,
        element: (
          <ProtectedRoute component={Designations} requiredAccess={18} moduleName="Designations" />
        ),
      },
      {
        path: '/designation/view/:Id',
        name: 'ViewCompany',
        exact: true,
        element: (
          <ProtectedRoute
            component={ViewDesignations}
            requiredAccess={18}
            moduleName="Designations"
          />
        ),
      },
      {
        path: '/configure/Access',
        name: 'AccessControl',
        exact: true,
        element: (
          <ProtectedRoute
            component={AccessControl}
            requiredAccess={6}
            moduleName="Access Control"
          />
        ),
      },
      {
        path: '/vendor/New',
        name: 'AddVendor',
        exact: true,
        element: (
          <ProtectedRoute component={AddVendor} requiredAccess={11} moduleName="Add Vendor" />
        ),
      },
      {
        path: '/vendor/All',
        name: 'Vendors',
        exact: true,
        element: <ProtectedRoute component={Vendors} requiredAccess={12} moduleName="Vendors" />,
      },
      {
        path: '/vendor/view/:vendorId',
        name: 'ViewVendor',
        exact: true,
        element: <ProtectedRoute component={ViewVendor} requiredAccess={12} moduleName="Vendors" />,
      },
      {
        path: '/farmer/New',
        name: 'AddFarmer',
        exact: true,
        element: (
          <ProtectedRoute component={AddFarmer} requiredAccess={9} moduleName="Add Farmer" />
        ),
      },
      {
        path: '/farmer/All',
        name: 'Farmers',
        exact: true,
        element: <ProtectedRoute component={Farmers} requiredAccess={10} moduleName="Farmers" />,
      },
      {
        path: '/farmer/view/:farmerId',
        name: 'ViewFarmer',
        exact: true,
        element: <ProtectedRoute component={ViewFarmer} requiredAccess={10} moduleName="Farmers" />,
      },
      {
        path: '/product/New',
        name: 'AddProduct',
        exact: true,
        element: (
          <ProtectedRoute component={AddProduct} requiredAccess={13} moduleName="Add Product" />
        ),
      },
      {
        path: '/product/All',
        name: 'Product',
        exact: true,
        element: <ProtectedRoute component={Product} requiredAccess={14} moduleName="Products" />,
      },
      {
        path: '/product/view/:productId',
        name: 'ViewProduct',
        exact: true,
        element: (
          <ProtectedRoute component={ViewProduct} requiredAccess={14} moduleName="Products" />
        ),
      },
      {
        path: '/product/Update/:productId',
        name: 'UpdateProduct',
        exact: true,
        element: (
          <ProtectedRoute component={EditProduct} requiredAccess={14} moduleName="Products" />
        ),
      },
      {
        path: '/Reports/All',
        name: 'Report',
        exact: true,
        element: <ProtectedRoute component={Reports} requiredAccess={15} moduleName="Reports" />,
      },
      {
        path: '/Reports/Attendance',
        name: 'Attendance',
        exact: true,
        element: (
          <ProtectedRoute component={AttendaceReport} requiredAccess={15} moduleName="Reports" />
        ),
      },
      {
        path: '/Reports/Attendance/Detail',
        name: 'Attendance',
        exact: true,
        element: (
          <ProtectedRoute
            component={DetailAttendanceReport}
            requiredAccess={15}
            moduleName="Reports"
          />
        ),
      },
      {
        path: '/Reports/Demonstration',
        name: 'Demonstration',
        exact: true,
        element: (
          <ProtectedRoute
            component={DemonstrationReport}
            requiredAccess={15}
            moduleName="Reports"
          />
        ),
      },
      {
        path: '/Reports/DemonstrationReport/Detail',
        name: 'Demonstration',
        exact: true,
        element: (
          <ProtectedRoute
            component={DetailDemonstrationReport}
            requiredAccess={15}
            moduleName="Reports"
          />
        ),
      },
      {
        path: '/Reports/FarmerMeeting',
        name: 'FarmerMeeting',
        exact: true,
        element: (
          <ProtectedRoute
            component={FarmerMeetingReport}
            requiredAccess={15}
            moduleName="Reports"
          />
        ),
      },
      {
        path: '/Reports/FarmerMeeting/Detail',
        name: 'FarmerMeeting',
        exact: true,
        element: (
          <ProtectedRoute
            component={DetailFarmerMeetingReport}
            requiredAccess={15}
            moduleName="Reports"
          />
        ),
      },
      {
        path: '/Reports/GeoTracking',
        name: 'GeoTracking',
        exact: true,
        element: (
          <ProtectedRoute component={GeoTrackingReport} requiredAccess={15} moduleName="Reports" />
        ),
      },
      {
        path: '/Reports/GeoTracking/Detail',
        name: 'GeoTracking',
        exact: true,
        element: (
          <ProtectedRoute
            component={DetailGeoTrackingReport}
            requiredAccess={15}
            moduleName="Reports"
          />
        ),
      },
      {
        path: '/Reports/MonthlyExpenses',
        name: 'Expense',
        exact: true,
        element: (
          <ProtectedRoute
            component={MonthlyExpensesReport}
            requiredAccess={15}
            moduleName="Reports"
          />
        ),
      },
      {
        path: '/Reports/MonthlyExpenses/Detail',
        name: 'Expense',
        exact: true,
        element: (
          <ProtectedRoute
            component={DetailMonthlyExpensesReport}
            requiredAccess={15}
            moduleName="Reports"
          />
        ),
      },
      {
        path: '/Reports/Expenses/Detail',
        name: 'Expense',
        exact: true,
        element: (
          <ProtectedRoute
            component={DetailExpensesReport}
            requiredAccess={15}
            moduleName="Reports"
          />
        ),
      },
      {
        path: '/Reports/Leave/Detail',
        name: 'Leave',
        exact: true,
        element: (
          <ProtectedRoute component={DetailLeaveReport} requiredAccess={15} moduleName="Reports" />
        ),
      },
      {
        path: '/Reports/Orders/Detail',
        name: 'Order',
        exact: true,
        element: (
          <ProtectedRoute component={DetailOrdersReport} requiredAccess={15} moduleName="Reports" />
        ),
      },
      {
        path: '/Reports/Task/Detail',
        name: 'Task',
        exact: true,
        element: (
          <ProtectedRoute component={DetailTaskReport} requiredAccess={15} moduleName="Reports" />
        ),
      },
      {
        path: '/Reports/TripMeter/Detail',
        name: 'TripMeter',
        exact: true,
        element: (
          <ProtectedRoute
            component={DetailTripMeterReport}
            requiredAccess={15}
            moduleName="Reports"
          />
        ),
      },
      {
        path: '/Reports/Collection/Detail',
        name: 'Collection',
        exact: true,
        element: (
          <ProtectedRoute
            component={DeatilCollectionReport}
            requiredAccess={15}
            moduleName="Reports"
          />
        ),
      },
      {
        path: '/Reports/TripMeter',
        name: 'TripMeter',
        exact: true,
        element: (
          <ProtectedRoute component={TripMeterReport} requiredAccess={15} moduleName="Reports" />
        ),
      },
      {
        path: '/Reports/Tasks',
        name: 'Tasks',
        exact: true,
        element: <ProtectedRoute component={TaskReport} requiredAccess={15} moduleName="Reports" />,
      },
      {
        path: '/Reports/Expenses',
        name: 'Expenses',
        exact: true,
        element: (
          <ProtectedRoute component={ExpensesReport} requiredAccess={15} moduleName="Reports" />
        ),
      },
      {
        path: '/Reports/Collections',
        name: 'Collections',
        exact: true,
        element: (
          <ProtectedRoute component={CollectionsReport} requiredAccess={15} moduleName="Reports" />
        ),
      },
      {
        path: '/Reports/Orders',
        name: 'Orders',
        exact: true,
        element: (
          <ProtectedRoute component={OrdersReport} requiredAccess={15} moduleName="Reports" />
        ),
      },
      {
        path: '/Reports/Leave',
        name: 'Leave',
        exact: true,
        element: (
          <ProtectedRoute component={LeaveReport} requiredAccess={15} moduleName="Reports" />
        ),
      },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: 'registerformik', element: <RegisterFormik /> },
      { path: 'login', element: <Login /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'lockscreen', element: <LockScreen /> },
      { path: 'recoverpwd', element: <RecoverPassword /> },
      { path: 'privacypolicy', element: <PrivacyPolicy /> },
      { path: 'deleteAccount', element: <DeleteAccount /> },
    ],
  },
];

export default ThemeRoutes;
